import { initializeExperiments } from '@shutterstock/experiments-sdk-next';
import { SupportedEnvironment } from '@shutterstock/experiments-types';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import { Request, Response } from 'express';

import { QueryKeys } from '@/constants/server';
import { CurrentUser } from '@/model/currentUser';

type GetInitialDataParams = {
  req: Request;
  res: Response;
  locale: string;
};

export const getInitialData = async ({ req, res, locale }: GetInitialDataParams) => {
  const { locals } = res;

  const anonymousId = locals?.anonymousId || '';
  const isLoggedIn = locals?.isLoggedIn || false;
  const region = locals?.region;

  const queryClient = new QueryClient();

  let userPromise = Promise.resolve({});

  if (isLoggedIn) {
    const currentUser = new CurrentUser(req);

    userPromise = currentUser.getCurrentUser().catch((e) => {
      req.newrelic?.noticeError?.(e);

      return {};
    });
  }

  const experimentsPromise = initializeExperiments({
    environment: (process.env.NODE_CONFIG_ENV || 'QA') as SupportedEnvironment,
    req,
    timeout: 300,
    user: {
      anonymousId,
      country: region,
      locale,
    },
    numRetries: 1,
  }).catch((e) => {
    req.newrelic?.noticeError?.(e);

    return {};
  });

  const promises = await Promise.allSettled([userPromise, experimentsPromise]);

  const [userCurrentInfo, experiments] = promises.map((promise) => {
    if (promise.status === 'rejected') {
      req.newrelic?.noticeError?.(promise.reason);

      return {};
    }

    if (promise.status === 'fulfilled') {
      return promise.value;
    }

    return {};
  });

  queryClient.setQueryData([QueryKeys.currentUser], userCurrentInfo);

  return {
    experiments,
    dehydratedState: dehydrate(queryClient),
  };
};
