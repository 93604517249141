import SvgIcon from '@mui/material/SvgIcon';

export const PublishIcon = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9497 2.00011C16.1107 2.00011 18.6997 4.45611 18.9277 7.55911C20.6647 8.26211 21.8977 9.96011 21.8977 11.9471C21.8977 13.6211 21.0197 15.0871 19.7057 15.9301L18.3157 14.5391C19.3077 14.0951 20.0027 13.1021 20.0027 11.9471C20.0027 10.3801 18.7277 9.10511 17.1597 9.10511C16.6597 9.10511 16.1677 9.23611 15.7387 9.48611L14.7867 7.84611C15.4227 7.47811 16.1387 7.27311 16.8717 7.22811C16.5117 5.39111 14.8917 4.00011 12.9497 4.00011C11.6367 4.00011 10.4347 4.64911 9.69369 5.69311C10.1877 5.88011 10.6607 6.12511 11.0937 6.44911L9.95669 7.96611C9.29669 7.47111 8.51069 7.21011 7.68469 7.21011C5.59469 7.21011 3.89469 8.91011 3.89469 11.0001C3.89469 12.3911 4.66469 13.6341 5.82569 14.2931L4.46069 15.6581C2.96769 14.6191 1.99969 12.9041 1.99969 11.0001C1.99969 7.89411 4.50369 5.36611 7.59869 5.31911C8.61169 3.29811 10.6717 2.00011 12.9497 2.00011ZM6.99829 15.949L11.9473 11L16.8973 15.949L15.4833 17.364L12.9473 14.828V22.035H10.9473V14.828L8.41229 17.364L6.99829 15.949Z"
    />
  </SvgIcon>
);
