import { createContext, FC, PropsWithChildren, useContext } from 'react';

type ContextProps = {
  flags: {
    'portfolio-inline-editing': boolean;
  };
};

export type InlineEditingProviderProps = {
  isInlineEditing?: boolean;
} & PropsWithChildren;

const InlineEditingContext = createContext<ContextProps>({ flags: { 'portfolio-inline-editing': false } });

export const useInlineEditingExperiment = () => {
  const context = useContext(InlineEditingContext);

  if (!context) {
    throw new Error('useInlineEditingExperiment must be used within a InlineEditingProvider');
  }

  return context;
};

export const InlineEditingProvider: FC<InlineEditingProviderProps> = ({ children, isInlineEditing = false }) => (
  <InlineEditingContext.Provider
    value={{
      flags: {
        'portfolio-inline-editing': isInlineEditing,
      },
    }}
  >
    {children}
  </InlineEditingContext.Provider>
);
