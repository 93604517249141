import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { PageSection } from '@shutterstock-private/react-data-tracking';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';

import { Link } from '@/components/Link';
import { Button } from '@/components/WithTracking/Button';
import { PortfolioPageSections, PortfolioTrackActions } from '@/constants/analytics';
import { useFetchUser } from '@/hooks/useFetchUser';
import { useBannerContext } from '@/providers/Banner/BannerProvider';

const MOBILE_BANNER_MIN_HEIGHT = 90;
const DESKTOP_BANNER_MIN_HEIGHT = 60;

type BannerProps = {
  initialBannerConfig: {
    portfolioLegacyExp: boolean;
    portfolioNextExp: boolean;
  };
};

const styles = {
  root: ({ breakpoints, palette }: Theme) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center' as const,
    backgroundColor: 'black',
    color: palette.white.main,
    minHeight: `${DESKTOP_BANNER_MIN_HEIGHT}px`,
    position: 'relative' as const,
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      minHeight: `${MOBILE_BANNER_MIN_HEIGHT}px`,
    },
  }),
  button: ({ palette }: Theme) => ({
    margin: 1,
    minWidth: 25,
    borderColor: palette.white.main,
    color: palette.white.main,
  }),
  text: {
    padding: 0.5,
  },
  link: ({ palette }: Theme) => ({
    textDecoration: 'none',
    color: palette.link.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  }),
};

export const Banner = function Banner({ initialBannerConfig }: BannerProps) {
  const { push } = useRouter();

  const { portfolioLegacyExp, portfolioNextExp } = initialBannerConfig;
  const [isLegacyExp, setIsLegacyExp] = useState(portfolioLegacyExp);
  const [isPortfolioNextExp, setIsPortfolioNextExp] = useState(!isLegacyExp);
  const { enabled, updateContainerRef } = useBannerContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { user } = useFetchUser();

  const shouldHideBannerForNewUser = !user || new Date(user?.details?.account?.createTime) > new Date('2024-08-14');

  useEffect(() => {
    setIsLegacyExp(portfolioLegacyExp);
    setIsPortfolioNextExp(portfolioNextExp);
  }, [portfolioLegacyExp, portfolioNextExp]);

  useEffect(() => {
    updateContainerRef(containerRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current, updateContainerRef]);

  useEffect(() => {
    /**
     * Recreate the cookies if the client has removed them or they don't exist.
     */
    if (!portfolioLegacyExp) {
      Cookies.set('portfolio_legacy_exp', 'false');
      Cookies.set('portfolio_next_exp', 'true');
      setIsLegacyExp(false);
    }

    if (!portfolioNextExp) {
      setIsPortfolioNextExp(true);
    }
  }, [portfolioLegacyExp, portfolioNextExp]);

  if ((!isPortfolioNextExp && !isLegacyExp) || !enabled || shouldHideBannerForNewUser) {
    return null;
  }

  const handleLegacyTrigger = async () => {
    if (!isLegacyExp) {
      Cookies.set('portfolio_legacy_exp', 'true');
      Cookies.set('portfolio_next_exp', 'false');
      setIsLegacyExp(true);
      setIsPortfolioNextExp(false);
      await push('/edit');
    } else {
      Cookies.set('portfolio_legacy_exp', 'false');
      Cookies.set('portfolio_next_exp', 'true');
      setIsLegacyExp(false);
      setIsPortfolioNextExp(true);
      await push('/portfolio/not_submitted/photo');
    }
  };

  const message = isLegacyExp ? t('banner_switch_new_message') : t('banner_switch_legacy_message');
  const buttonText = isLegacyExp ? t('banner_new_button') : t('banner_legacy_button');

  return (
    <PageSection value={PortfolioPageSections.legacyBanner}>
      <Box sx={styles.root} ref={containerRef}>
        <Typography sx={styles.text} color="common.white" variant="body2">
          {message}
        </Typography>
        <Button
          sx={styles.button}
          onClick={handleLegacyTrigger}
          trackAction={`banner_change_to_${isLegacyExp ? 'new' : 'legacy'}_exp`}
          variant="secondary"
        >
          {buttonText}
        </Button>
        <Button
          component={Link}
          variant="secondary"
          target="_blank"
          sx={styles.button}
          href="https://airtable.com/appyyKyPqwlMO9LJY/shrsos7IyQkB50h5A"
          trackAction={PortfolioTrackActions.feedback}
        >
          {t('banner_feedback_button')}
        </Button>
      </Box>
    </PageSection>
  );
};
