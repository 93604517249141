import { ButtonProps } from '@mui/material/Button';
import { withClickTracking } from '@shutterstock-private/react-data-tracking';

import { ButtonWithoutTracking as FormButton } from '@/components/Form/FormElements/Button/ButtonWithoutTracking';

interface ExtendedButtonProps extends ButtonProps {
  trackAction: string;
  sxTypography?: object;
  target?: string;
}

const UntrackedButton: React.FC<ExtendedButtonProps> = function UntrackedButton(props) {
  return <FormButton {...props} />;
};

export const Button = withClickTracking(UntrackedButton);
