import type { WithExperimentConfig } from '@shutterstock/experiments-types';
import { withVisualizedExperiment } from '@shutterstock/experiments-visualizer';

import { InlineEditingProvider } from '@/providers/InlineEditingProvider/InlineEditingProvider';

import { Variant1 } from './Variant1';

export const config: WithExperimentConfig = {
  feature: InlineEditingProvider,
  id: 'contrib-4441-inline-editing-v2',
  variations: [Variant1],
};

export const ExperimentInlineEditingProvider = withVisualizedExperiment(config.feature, config.id, config.variations);
