import SvgIcon from '@mui/material/SvgIcon';

export const DocumentIcon = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 18C20 19.103 19.103 20 18 20H4V4H20V18ZM2 2V22H18C20.206 22 22 20.206 22 18V2H2ZM6 8.999H7.998V7H6V8.999ZM18 8.999H10.001V7.001H18V8.999ZM6 12.999H7.998V11H6V12.999ZM18 13H10.001V11.001H18V13ZM6 17H7.998V15.001H6V17ZM18 17.001H10.001V15.002H18V17.001Z"
    />
  </SvgIcon>
);
