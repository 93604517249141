import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LanguageIcon from '@mui/icons-material/Language';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Theme, useTheme } from '@mui/material/styles';
import { useRouter } from 'next/router';
import { CSSProperties } from 'react';

import { languages } from '@/../public/static/languages/sortedList';
import { LANGUAGE_CONSTANTS } from '@/constants/global';
import { syncLocaleCookies } from '@/utils/syncLocaleCookies';

import { createStyles, getMenuProps } from './styles';

type LanguageSelectProps = {
  sx?: object;
  color?: CSSProperties['color'] | (({ palette }: Theme) => CSSProperties['color']);
  noIcon?: boolean;
};

export const LanguageSelect: React.FC<LanguageSelectProps> = ({ sx, color, noIcon = false }) => {
  const { tokens, spacing, palette, breakpoints } = useTheme();
  const classes = createStyles(tokens, spacing, breakpoints, color, noIcon);
  const MenuProps = getMenuProps(tokens, palette);
  const router = useRouter();

  const getRouterLanguage = languages.filter((item) => item.code === router.locale);
  const [selectedLanguage] = getRouterLanguage.length !== 0 ? getRouterLanguage : LANGUAGE_CONSTANTS.DEFAULT_LANG;

  const onChangeHandler = async (event: SelectChangeEvent) => {
    await syncLocaleCookies({ router, setToLocale: event.target.value });
  };

  const setCurrentLanguage = (code: string, selected: { code: string }) => ({
    backgroundColor: code === selected.code ? palette.background.lightGrey : palette.background.darkGrey,
  });

  return (
    <Box sx={{ alignItems: 'flex-end' }} data-testid="LanguageSelect">
      <FormControl sx={[classes.languageSelectContainer, ...(Array.isArray(sx) ? sx : [sx])]} fullWidth>
        <Select
          IconComponent={KeyboardArrowDownIcon}
          data-testid="select-language"
          variant="outlined"
          displayEmpty
          value={selectedLanguage.code}
          onChange={onChangeHandler}
          input={<OutlinedInput sx={[classes.inputRoot]} label="Language" data-testid="select-input" />}
          renderValue={() => selectedLanguage.label}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'select_language', 'data-testid': 'select_language' }}
          startAdornment={
            <InputAdornment position="start" sx={classes.globeAdornment}>
              <LanguageIcon />
            </InputAdornment>
          }
        >
          {languages.map(({ code, label }) => (
            <MenuItem data-testid={label} key={code} value={code} sx={setCurrentLanguage(code, selectedLanguage)}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
