import SvgIcon from '@mui/material/SvgIcon';

export const VideoIcon = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 16C20 17.103 19.103 18 18 18H4V6H20V16ZM2 4V20H18C20.206 20 22 18.206 22 16V4H2ZM9 8.382V15.618L16.236 12L9 8.382Z"
    />
  </SvgIcon>
);
