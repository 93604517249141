import SvgIcon from '@mui/material/SvgIcon';

export const SearchIcon = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 10C4 6.691 6.691 4 10 4C13.309 4 16 6.691 16 10C16 13.309 13.309 16 10 16C6.691 16 4 13.309 4 10V10ZM22 20.586L16.312 14.897C17.366 13.542 18 11.846 18 10C18 5.589 14.411 2 10 2C5.589 2 2 5.589 2 10C2 14.411 5.589 18 10 18C11.846 18 13.542 17.365 14.897 16.312L20.586 22L22 20.586Z"
    />
  </SvgIcon>
);
