import SvgIcon from '@mui/material/SvgIcon';

export const TwitterIcon = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.191 20C15.621 20 19.683 13.845 19.683 8.508C19.683 8.333 19.68 8.159 19.672 7.985C20.461 7.416 21.146 6.704 21.687 5.894C20.963 6.216 20.184 6.433 19.368 6.53C20.201 6.03 20.841 5.239 21.143 4.296C20.363 4.758 19.499 5.095 18.579 5.276C17.841 4.491 16.792 4 15.63 4C13.4 4 11.59 5.809 11.59 8.039C11.59 8.356 11.627 8.664 11.696 8.96C8.338 8.791 5.362 7.184 3.37 4.74C3.023 5.336 2.823 6.03 2.823 6.77C2.823 8.171 3.536 9.408 4.621 10.132C3.958 10.112 3.336 9.93 2.791 9.626C2.79 9.644 2.79 9.661 2.79 9.678C2.79 11.635 4.182 13.268 6.031 13.638C5.691 13.73 5.335 13.78 4.965 13.78C4.706 13.78 4.453 13.754 4.207 13.707C4.72 15.312 6.212 16.48 7.98 16.513C6.597 17.596 4.856 18.242 2.963 18.242C2.637 18.242 2.316 18.223 2 18.186C3.788 19.332 5.91 20 8.191 20"
    />
  </SvgIcon>
);
