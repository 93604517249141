import SvgIcon from '@mui/material/SvgIcon';

export const CatalogIcon = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.196 2.9996L2.023 12.8836L2 21.0986H22V13.0986L19.804 2.9996H4.196ZM5.803 5.0006H18.196L19.757 12.0986H4.243L5.803 5.0006ZM4 19.0986H20V14.0986H4V19.0986ZM7 11.0986H17V9.0986H7V11.0986ZM16 8.0986H8V6.0986H16V8.0986Z"
    />
  </SvgIcon>
);
