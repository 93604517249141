import { useContext, useEffect } from 'react';

import { debounce } from '@/utils/debounce';
import { noop } from '@/utils/noop';

import { UploadsContext } from './UploadsContext';

export const useUploadsContext = ({ mutationHandler } = {}) => {
  const context = useContext(UploadsContext);

  useEffect(() => {
    if (mutationHandler) {
      context.mutationHandler.current = debounce(mutationHandler, 500);

      return () => {
        context.mutationHandler.current = noop;
      };
    }

    return noop;
  }, [mutationHandler, context.mutationHandler]);

  return context;
};
