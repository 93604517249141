import SvgIcon from '@mui/material/SvgIcon';

export const YoutubeIcon = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.935 14.627L9.934 9.008L15.338 11.828L9.935 14.627ZM21.8 8.035C21.8 8.035 21.604 6.656 21.005 6.049C20.245 5.252 19.392 5.249 19.001 5.202C16.203 5 12.004 5 12.004 5H11.996C11.996 5 7.797 5 4.998 5.202C4.607 5.249 3.755 5.252 2.995 6.049C2.395 6.656 2.2 8.035 2.2 8.035C2.2 8.035 2 9.653 2 11.272V12.789C2 14.407 2.2 16.025 2.2 16.025C2.2 16.025 2.395 17.404 2.995 18.011C3.755 18.808 4.755 18.782 5.2 18.865C6.8 19.02 12 19.066 12 19.066C12 19.066 16.203 19.061 19.001 18.857C19.392 18.812 20.245 18.808 21.005 18.011C21.604 17.404 21.8 16.025 21.8 16.025C21.8 16.025 22 14.407 22 12.789V11.272C22 9.653 21.8 8.035 21.8 8.035V8.035Z"
    />
  </SvgIcon>
);
