import './global.css';

import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { useTheme } from '@mui/material/styles';
import { ExperimentsProvider } from '@shutterstock/experiments-sdk-next';
import type { Payload } from '@shutterstock/experiments-types';
import { VisualizedExperimentsProvider } from '@shutterstock/experiments-visualizer';
import { DataTrackingProvider, PageSection } from '@shutterstock-private/react-data-tracking';
import { HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { Request, Response } from 'express';
import Cookies from 'js-cookie';
import App, { AppContext, AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { appWithTranslation, UserConfig, useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';

import { AccessibleLoader } from '@/components/AccessibleLoader/AccessibleLoader';
import { Banner } from '@/components/Banner/Banner';
import { SubMenuDrawerProvider } from '@/components/SiteMenu/SubMenuDrawer/hooks/provider';
import { UploadsProvider } from '@/components/UploadSnackbar/Context';
import { GlobalPageSections } from '@/constants/analytics';
import { QueryKeys } from '@/constants/server';
import { ExperimentInlineEditingProvider } from '@/experiments/CONTRIB/CONTRIB-4441';
import { useAnalyticalWrapper } from '@/hooks/useAnalyticalWrapper';
import { useReportWebVitals } from '@/hooks/useReportWebVitals';
import { PrimaryLayout } from '@/layouts/PrimaryLayout';
import { AnalyticsProvider } from '@/lib/analytics';
import { getInitialData } from '@/middleware/getInitialData';
import { setupMockServer } from '@/mocks';
import { BannerProvider } from '@/providers/Banner/BannerProvider';
import { BrandProvider } from '@/providers/Brand/BrandProvider';
import { FeatureFlagsProvider } from '@/providers/FeatureFlagsProvider';
import { ModalProvider } from '@/providers/Modal';
import { NotificationsProvider } from '@/providers/Notifications';
import { PageFormStateProvider } from '@/providers/PageFormState/PageFormStateProvider';
import { RegionProvider } from '@/providers/Region/RegionProvider';
import { useUserStore } from '@/stores/useUserStore';
import { sstkLightTheme } from '@/theme';
import { NAMESPACE_COMMON } from '@/translations/namespaces';
import { createEmotionCache } from '@/utils/createEmotionCache';
import { isBrowser } from '@/utils/isBrowser';
import { syncLocaleCookies } from '@/utils/syncLocaleCookies';

import nextI18NextConfig from '../../next-i18next.config';

const UploadSnackbar = dynamic(() => import('@/components/UploadSnackbar').then((mod) => mod.UploadSnackbar));

// necessary because we use a non-standard NODE_ENV value
const ReactQueryDevtools = dynamic(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then((mod) => mod.ReactQueryDevtools),
);

// eslint-disable-next-line @typescript-eslint/no-floating-promises
setupMockServer();

const registerServiceWorker = () => {
  if (!('serviceWorker' in navigator)) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  navigator.serviceWorker.register('/service-worker.js');
};

type QueryItem = {
  state: object;
  queryKey: string[];
  queryHash: string;
};

type DehydratedState = {
  mutations: any[];
  queries: QueryItem[];
};

type MobileServerProviderValue = {
  isMobileServer: boolean;
  isSlowMobileServer: boolean;
};

type MyAppProps = {
  Component: any;
  dehydratedState: DehydratedState;
  pageProps: any;
  region: string;
  emotionCache: any;
  isLoggedIn: boolean;
  experiments?: Payload;
  mobileServerProps: MobileServerProviderValue;
  legacyBanner: {
    portfolioLegacyExp: boolean;
    portfolioNextExp: boolean;
  };
};

const MyApp = function MyApp({
  Component,
  dehydratedState,
  pageProps,
  region,
  emotionCache = createEmotionCache(),
  isLoggedIn,
  experiments,
  mobileServerProps,
  legacyBanner,
}: AppProps & MyAppProps) {
  if (isBrowser && window.NREUM?.setApplicationVersion) {
    // https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/setApplicationVersion/
    const clientVersion = Cookies.get('n_v');

    window.NREUM.setApplicationVersion(clientVersion);
  }

  useReportWebVitals();
  const setLoggedInState = useUserStore((state) => state.setIsLoggedIn);

  setLoggedInState(isLoggedIn);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // With SSR, we usually want to set some default staleTime
            // above 0 to avoid refetching immediately on the client
            staleTime: 15 * 60 * 1000,
          },
        },
      }),
  );

  const router = useRouter();
  const { pageTitle = 'contributor_lihp.title', displayBanner = true, shouldIndex = false } = pageProps;
  const { palette } = useTheme();
  const { t } = useTranslation(NAMESPACE_COMMON);
  const [loaderBackground, setLoaderBackground] = useState('none');

  const loadingBarRef = useRef<LoadingBarRef>(null);

  const userQuery = dehydratedState.queries.find((item: QueryItem) => item.queryHash.includes(QueryKeys.currentUser));
  const user = userQuery ? userQuery.state : undefined;
  const analytics = useAnalyticalWrapper(user, region, router.locale);

  const pageTitleFull = `${t(pageTitle)} | Shutterstock`;
  // Uses PrimaryLayout as default unless layout type key exists on page component object.
  const LayoutComponent = Component?.layout?.type || PrimaryLayout;
  const layoutProps = Component?.layout?.props;

  useEffect(() => {
    const handleLoadingStart = () => {
      setLoaderBackground(palette.grey['500']);

      return loadingBarRef.current && loadingBarRef.current.continuousStart();
    };
    const handleLoadingComplete = () => {
      setLoaderBackground('transparent');

      return loadingBarRef.current && loadingBarRef.current.complete();
    };

    router.events.on('routeChangeStart', handleLoadingStart);
    router.events.on('routeChangeComplete', handleLoadingComplete);
    router.events.on('routeChangeError', handleLoadingComplete);

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    syncLocaleCookies({ router, setToLocale: null });

    return () => {
      router.events.off('routeChangeStart', handleLoadingStart);
      router.events.off('routeChangeComplete', handleLoadingComplete);
      router.events.off('routeChangeError', handleLoadingComplete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    registerServiceWorker();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <HydrationBoundary state={dehydratedState}>
        <RegionProvider region={region || ''}>
          <CacheProvider value={emotionCache}>
            <AnalyticsProvider value={{ analytics }}>
              <FeatureFlagsProvider user={user}>
                <DataTrackingProvider awInstance={analytics}>
                  <ExperimentsProvider experiments={experiments || {}} analytics={analytics}>
                    <VisualizedExperimentsProvider analytics={analytics} experiments={experiments || {}}>
                      <PageSection value={GlobalPageSections.contributorRoot}>
                        <Head>
                          {/* Primary Meta Tags */}
                          <title>{pageTitleFull}</title>
                          <meta name="robots" content={shouldIndex ? 'index' : 'noindex'} />
                          <meta name="title" content={t(pageTitle)} key="title" />
                          <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
                          <meta name="description" content={t('contributor_helmet_description')} key="description" />
                          <meta name="keywords" content={t('contributor_helmet_keywords')} key="keywords" />

                          {/* Open Graph / Facebook */}
                          <meta property="og:title" content={t(pageTitle)} key="og:title" />
                          <meta property="og:type" content="company" />
                          <meta property="og:url" content="https://submit.shutterstock.com/" />
                          <meta property="og:site_name" content="Shutterstock" />
                          <meta property="og:description" content={t('contributor_helmet_description')} />
                          <meta
                            property="og:image"
                            content="https://submit.shutterstock.com/assets/images/payouts_hero_mobile.jpg"
                          />

                          {/* Twitter */}
                          <meta property="twitter:card" content="summary_large_image" />
                          <meta property="twitter:url" content="https://submit.shutterstock.com/" />
                          <meta property="twitter:title" content={t(pageTitle)} key="twitter:title" />
                          <meta property="twitter:description" content={t('contributor_helmet_description')} />
                          <meta
                            property="twitter:image"
                            content="https://submit.shutterstock.com/assets/images/payouts_hero_mobile.jpg"
                          />
                        </Head>
                        <BrandProvider isMobileServer={mobileServerProps?.isMobileServer}>
                          <LoadingBar
                            shadow={false}
                            ref={loadingBarRef}
                            className="loading-bar"
                            color={sstkLightTheme.palette.secondary.main}
                            background={loaderBackground}
                          />
                          <AccessibleLoader />

                          <BannerProvider enabled={displayBanner}>
                            <UploadsProvider>
                              <PageFormStateProvider>
                                <ModalProvider>
                                  <NotificationsProvider>
                                    <SubMenuDrawerProvider>
                                      <CssBaseline />
                                      <Banner initialBannerConfig={legacyBanner} />
                                      <ExperimentInlineEditingProvider>
                                        <LayoutComponent {...layoutProps}>
                                          <Component {...pageProps} />
                                          <UploadSnackbar />
                                        </LayoutComponent>
                                      </ExperimentInlineEditingProvider>
                                    </SubMenuDrawerProvider>
                                  </NotificationsProvider>
                                </ModalProvider>
                              </PageFormStateProvider>
                            </UploadsProvider>
                          </BannerProvider>
                        </BrandProvider>
                      </PageSection>
                    </VisualizedExperimentsProvider>
                  </ExperimentsProvider>
                </DataTrackingProvider>
              </FeatureFlagsProvider>
            </AnalyticsProvider>
          </CacheProvider>
        </RegionProvider>
      </HydrationBoundary>
      {!['production', 'test'].includes(process.env.NODE_ENV) && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
};

MyApp.getInitialProps = async (appContext: AppContext) => {
  // Calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);
  const { ctx } = appContext;
  const { locals } = ctx.res as Response;
  let isLoggedIn = false;
  const region = locals?.region;
  const portfolioLegacyExp = (ctx.req as any)?.cookies?.portfolio_legacy_exp === 'true';
  const portfolioNextExp = (ctx.req as any)?.cookies?.portfolio_next_exp === 'true';

  if (locals) {
    isLoggedIn = locals?.isLoggedIn;
  }

  const mobileServerProps = {
    isMobileServer: locals?.isMobileServer ?? false,
    isSlowMobileServer: locals?.isSlowMobileServer ?? false,
  };

  const { experiments, dehydratedState } = await getInitialData({
    req: ctx.req as Request,
    res: ctx.res as Response,
    locale: ctx.locale ?? 'en',
  });

  return {
    ...appProps,
    dehydratedState,
    region,
    isLoggedIn,
    experiments,
    mobileServerProps,
    legacyBanner: {
      portfolioLegacyExp,
      portfolioNextExp,
    },
  };
};

export default appWithTranslation(MyApp, nextI18NextConfig as UserConfig);
