import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { PageSection } from '@shutterstock-private/react-data-tracking';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { Button } from '@/components/WithTracking/Button';
import { CommonPageSections, CommonTrackActions } from '@/constants/analytics';
import { useConfirmationComponent } from '@/hooks/useConfirmationComponent';
import { usePageFormState } from '@/hooks/usePageFormState';
import { NAMESPACE_COMMON } from '@/translations/namespaces';

const actionButtonStyle = ({ spacing }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  mt: spacing(5.25),
});

export const UnsavedModalContent = ({ onClose = () => {}, href = null }) => {
  const { t } = useTranslation(NAMESPACE_COMMON);

  const { setPageHasDirtyForm } = usePageFormState();

  const { closeConfirmation } = useConfirmationComponent();
  const router = useRouter();

  const leave = () => {
    setPageHasDirtyForm(false);
    onClose();

    // Handle global navigation
    if (href) {
      router.push(href);
    }

    closeConfirmation();
  };

  return (
    <PageSection value={CommonPageSections.unsavedDialog}>
      <Box data-testid="unsaved-changes-modal">
        <Typography id="modal-title" data-testid="modal-title" variant="h4" component="h2">
          {t('unsaved_changes')}
        </Typography>
        <Typography id="modal-description" variant="body1" sx={{ mt: 2 }}>
          {t('unsaved_changes_content')}
        </Typography>
        <Box sx={actionButtonStyle}>
          <Button variant="secondary" onClick={closeConfirmation} trackAction={CommonTrackActions.stay}>
            {t('unsaved_changes_stay')}
          </Button>
          <Button onClick={leave} trackAction={CommonTrackActions.leave}>
            {t('unsaved_changes_leave')}
          </Button>
        </Box>
      </Box>
    </PageSection>
  );
};
