import MUICheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import { withClickTracking } from '@shutterstock-private/react-data-tracking';
import { FC } from 'react';

interface ExtendedCheckboxProps extends CheckboxProps {
  trackAction: string;
  target?: string;
}

const UntrackedCheckbox: FC<ExtendedCheckboxProps> = function UntrackedCheckbox(props) {
  return <MUICheckbox {...props} />;
};

export const Checkbox = withClickTracking(UntrackedCheckbox);
