import PersonOutline from '@mui/icons-material/PersonOutline';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { useTranslation } from 'next-i18next';

import { getPublicProfile } from '@/apiRoutes/publicProfile';
import { Link } from '@/components/Link';
import { IconButton } from '@/components/WithTracking/IconButton';
import { NavTrackActions } from '@/constants/analytics';
import { LINK_TYPE, SITE_ROUTES } from '@/constants/global';
import { useFetch } from '@/hooks/useFetch';
import { useFetchUser } from '@/hooks/useFetchUser';
import { NAMESPACE_COMMON } from '@/translations/namespaces';
import { createProfileCdnUrl, useFormatLink } from '@/utils/url';

import { classes } from './styles';

export const ProfileInformation = () => {
  const { t } = useTranslation(NAMESPACE_COMMON);
  const { user } = useFetchUser();
  const contributorId = user?.details?.account?.contributorId || '';
  const contributorName = user?.details?.account?.fullName || '';

  const { data: userPublicProfileData } = useFetch(contributorId ? getPublicProfile(contributorId).formattedUrl : null);
  const profileImageCdnPath = userPublicProfileData?.publicInformation.cdnThumbPath;
  const hasProfileImage = userPublicProfileData?.publicInformation?.storageKey;

  const accountSettingsLink = useFormatLink({
    href: SITE_ROUTES.ACCOUNT_SETTINGS_PAGE,
    linkType: LINK_TYPE.CCWEB_REDIRECT,
  });

  return (
    <Box sx={classes.drawerProfileInfo}>
      <Box sx={classes.avatar}>
        {profileImageCdnPath && hasProfileImage ? (
          <Avatar sx={classes.avatarIcon} src={createProfileCdnUrl(profileImageCdnPath)} />
        ) : (
          <Link href={accountSettingsLink}>
            <IconButton sx={classes.userIcon} trackAction={NavTrackActions.accountSettingsLink}>
              <PersonOutline />
            </IconButton>
          </Link>
        )}
      </Box>
      <Box sx={classes.drawerProfileTextInfo}>
        <Box sx={classes.userNameText}>{t('hi_first_name', { first_name: contributorName })}</Box>
        <Box sx={classes.userIdText}>Contributor ID: {contributorId}</Box>
      </Box>
    </Box>
  );
};
