import CloseIcon from '@mui/icons-material/Close';

import { useSubMenuDrawer } from '@/components/SiteMenu/SubMenuDrawer/hooks';
import { IconButton } from '@/components/WithTracking/IconButton';
import { CommonTrackActions } from '@/constants/analytics';
import { MenuIcon } from '@/icons/MenuIcon';

export const SiteHeaderMobileMenu = () => {
  const { isDrawerOpen, setDrawer } = useSubMenuDrawer();

  return (
    <IconButton onClick={() => setDrawer('Earnings')} trackAction={CommonTrackActions.toggle}>
      {isDrawerOpen ? (
        <CloseIcon data-testid="CloseIcon" variant="action" />
      ) : (
        <MenuIcon data-testid="MenuIcon" variant="action" />
      )}
    </IconButton>
  );
};
