import SvgIcon from '@mui/material/SvgIcon';

export const PortfolioIcon = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8182 2C14.3004 2 14.7629 2.19156 15.1038 2.53253C15.4448 2.87351 15.6364 3.33597 15.6364 3.81818V4.72727H19.2727C19.996 4.72727 20.6897 5.01461 21.2012 5.52607C21.7127 6.03754 22 6.73123 22 7.45455V19.2727C22 19.996 21.7127 20.6897 21.2012 21.2012C20.6897 21.7127 19.996 22 19.2727 22H4.72727C4.00396 22 3.31026 21.7127 2.7988 21.2012C2.28734 20.6897 2 19.996 2 19.2727V7.45455C2 6.73123 2.28734 6.03754 2.7988 5.52607C3.31026 5.01461 4.00396 4.72727 4.72727 4.72727H8.36364V3.81818C8.36364 3.33597 8.55519 2.87351 8.89617 2.53253C9.23714 2.19156 9.69961 2 10.1818 2H13.8182ZM20.1818 12.9091H3.81818V19.2727C3.81657 19.4961 3.89723 19.7122 4.04478 19.8799C4.19233 20.0475 4.39645 20.155 4.61818 20.1818H19.2727C19.5138 20.1818 19.7451 20.086 19.9156 19.9156C20.086 19.7451 20.1818 19.5138 20.1818 19.2727V12.9091ZM19.2727 6.54545H4.72727C4.48617 6.54545 4.25494 6.64123 4.08445 6.81172C3.91396 6.98221 3.81818 7.21344 3.81818 7.45455V11.0909H20.1818V7.45455C20.1834 7.2312 20.1028 7.01508 19.9552 6.84741C19.8077 6.67974 19.6036 6.57225 19.3818 6.54545H19.2727ZM13.8182 3.81818H10.1818V4.72727H13.8182V3.81818Z"
    />
  </SvgIcon>
);
