import SvgIcon from '@mui/material/SvgIcon';

export const FacebookIcon = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.951 2H4.048C3.469 2 3 2.469 3 3.049V19.951C3 20.53 3.469 21 4.048 21H13.148V13.642H10.672V10.775H13.148V8.66C13.148 6.206 14.646 4.87 16.836 4.87C17.885 4.87 18.786 4.948 19.049 4.982V7.547L17.53 7.548C16.34 7.548 16.109 8.114 16.109 8.944V10.775H18.949L18.579 13.642H16.109V21H20.951C21.53 21 22 20.53 22 19.951V3.049C22 2.469 21.53 2 20.951 2"
    />
  </SvgIcon>
);
