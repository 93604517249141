import SvgIcon from '@mui/material/SvgIcon';

export const ChatIcon = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 14H7V16.965L10.645 14H19C19.552 14 20 13.551 20 13V5H4V14ZM5 21.004V16H2V3H22V13C22 14.654 20.654 16 19 16H11.355L5 21.004Z"
    />
  </SvgIcon>
);
