import SvgIcon from '@mui/material/SvgIcon';

export const PhotosIcon = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 15C10.896 15 10 14.103 10 13C10 11.897 10.896 11 12 11C13.104 11 14 11.897 14 13C14 14.103 13.104 15 12 15V15ZM12 9C9.795 9 8 10.794 8 13C8 15.206 9.795 17 12 17C14.205 17 16 15.206 16 13C16 10.794 14.205 9 12 9V9ZM20 16C20 17.103 19.104 18 18 18H4V9H8C8.553 9 9 8.552 9 8V6H15V8C15 8.552 15.447 9 16 9H20V16ZM17 7V4H7V7H2V20H18C20.205 20 22 18.206 22 16V7H17Z"
    />
  </SvgIcon>
);
