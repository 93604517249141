import SvgIcon from '@mui/material/SvgIcon';

export const AllImagesIcon = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 12.586L16 8.586L10 14.586L8 12.586L4 16.586V6H20V12.586ZM20 16C20 17.103 19.104 18 18 18H5.414L8 15.414L10 17.414L16 11.414L20 15.414V16ZM2 4V20H18C20.205 20 22 18.206 22 16V4H2ZM7.5 11C8.328 11 9 10.329 9 9.5C9 8.671 8.328 8 7.5 8C6.672 8 6 8.671 6 9.5C6 10.329 6.672 11 7.5 11V11Z"
    />
  </SvgIcon>
);
