import { useTheme } from '@emotion/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { PageSection } from '@shutterstock-private/react-data-tracking';
import { useTranslation } from 'next-i18next';

import { Button } from '@/components/WithTracking/Button';
import { CommonTrackActions, NavigationPageSections } from '@/constants/analytics';
import { LINK_TYPE } from '@/constants/global';
import { useFormatLink } from '@/hooks/useFormatLink';
import { NAMESPACE_COMMON } from '@/translations/namespaces';

const createStyles = ({ breakpoints, spacing, tokens }) => ({
  container: {
    bgcolor: 'link.main',
    color: 'link.contrastText',
    borderRadius: tokens.border.radius.l,
    margin: spacing('m'),
    mb: spacing('s'),
    [breakpoints.down('md')]: { margin: 0 },
    [breakpoints.down('sm')]: { mb: spacing('s') },
    padding: spacing('base'),
    pb: spacing(1.8),
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.only('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  textContainer: {
    [breakpoints.only('sm')]: {
      maxWidth: spacing(50),
    },
  },
  titleText: { overflowWrap: 'normal' },
  bodyText: { mt: spacing(1) },
  leftButton: {
    ml: 0,
    padding: spacing(1.5),
    '& p': { fontWeight: 'bold' },
  },
  buttonsContainer: {
    mt: spacing(1),
    display: 'flex',
    justifyContent: 'flex-start',
    flexShrink: 0,
    [breakpoints.only('sm')]: {
      mt: 0,
    },
  },
});

export const MenuAnnouncement = () => {
  const { t } = useTranslation(NAMESPACE_COMMON);

  const classes = createStyles(useTheme());

  // When CMS is introduced to this component, the `data` object below should be
  // removed, and a fetch from the CMS should be destructured like:
  // const { titleText, bodyText, leftButton, rightButton} = ...
  const data = {
    titleText: 'welcome_announcement',
    bodyText: 'welcome_announcement_body',
    leftButton: {
      text: 'leave_feedback',
      link: {
        href: 'https://airtable.com/appyyKyPqwlMO9LJY/shroz2fUcAvPYQVDn',
        target: '_blank',
      },
    },
    rightButton: {
      text: 'learn_more_link',
      link: {
        linkType: 'CONTRIBUTOR_SUPPORT_CENTER',
        href: '/s/article/Shutterstock-Contributor-Platform-Gets-an-Update-in-2022',
        target: '_blank',
      },
    },
  };

  // The CMS will provide translations for text, and this will be based on the
  // locale included in that GET request. But until this is implemented, we have to work
  // with useTranslation(). So for the timebeing, I've set the text values in the above mock
  // CMS fetch data to be the translation keys, and then used t() on those keys, below.
  // Remove the following when actual data from CMS is being fetched:
  const { titleText, bodyText, leftButton, rightButton } = {
    titleText: t(data.titleText),
    bodyText: t(data.bodyText),
    leftButton: { ...data.leftButton, text: t(data.leftButton.text) },
    rightButton: { ...data.rightButton, text: t(data.rightButton.text) },
  };

  const leftButtonLink = useFormatLink({
    href: leftButton.link.href,
    linkType: LINK_TYPE[leftButton.link.linkType],
  });

  const rightButtonLink = useFormatLink({
    href: rightButton.link.href,
    linkType: LINK_TYPE[rightButton.link.linkType],
  });

  const handleLeftButtonClick = () => window.open(leftButtonLink, leftButton.link.target);
  const handleRightButtonClick = () => window.open(rightButtonLink, rightButton.link.target);

  return (
    <PageSection value={NavigationPageSections.menuAnnouncement}>
      <Box sx={classes.container}>
        <Box sx={classes.textContainer}>
          <Typography variant="h6" sx={classes.titleText}>
            {titleText}
          </Typography>
          <Typography variant="subtitle1" sx={classes.bodyText}>
            {bodyText}
          </Typography>
        </Box>
        <Box sx={classes.buttonsContainer}>
          <Button
            data-testid="announcement-left-button"
            variant="secondaryInverse"
            onClick={handleLeftButtonClick}
            sx={[classes.leftButton]}
            trackAction={CommonTrackActions.left}
          >
            {leftButton.text}
          </Button>
          <Button
            data-testid="announcement-right-button"
            variant="linkInverse"
            onClick={handleRightButtonClick}
            trackAction={CommonTrackActions.right}
          >
            {rightButton.text}
          </Button>
        </Box>
      </Box>
    </PageSection>
  );
};
