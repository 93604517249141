import MUIIconButton, { IconButtonProps } from '@mui/material/IconButton';
import { withClickTracking } from '@shutterstock-private/react-data-tracking';
import { FC } from 'react';

interface ExtendedIconButtonProps extends IconButtonProps {
  trackAction: string;
}

const UntrackedIconButton: FC<ExtendedIconButtonProps> = function UntrackedIconButton(props) {
  return <MUIIconButton {...props} />;
};

export const IconButton = withClickTracking(UntrackedIconButton);
